import { hash } from 'rsvp';
import { type Registry as Services, inject as service } from '@ember/service';
import type Transition from '@ember/routing/transition';
import AccountBillingController from 'uplisting-frontend/pods/account/billing/controller';
import PermittedRoute from 'uplisting-frontend/pods/base/permitted-route';
import CustomerModel from 'uplisting-frontend/models/customer';
import UserModel from 'uplisting-frontend/models/user';

export default class AccountBillingRoute extends PermittedRoute {
  @service('repositories/property')
  propertyRepository!: Services['repositories/property'];

  @service('repositories/seam-connection')
  seamConnectionRepositoryService!: Services['repositories/seam-connection'];

  @service('repositories/security-deposit-setting')
  securityDepositSettingRepository!: Services['repositories/security-deposit-setting'];

  @service('repositories/guest-identity-verification-configuration')
  guestIdentityVerificationConfigurationRepository!: Services['repositories/guest-identity-verification-configuration'];

  permission = 'account.billing';

  model() {
    return hash({
      properties: this.propertyRepository.findAll(),
      customer: this.getUserCustomerOrDefault(this.currentUser),
      securityDepositSettings: this.securityDepositSettingRepository.findAll(),
      guestIdentityVerificationConfiguration:
        this.guestIdentityVerificationConfigurationRepository.getDefaultRecord(),
      changeoverSettings: this.store.findAll('changeover-setting'),
      seamConnections: this.currentUser.hasAccessTo('connect.smart_locks')
        ? this.seamConnectionRepositoryService.findAll()
        : [],
    });
  }

  setupController(
    controller: AccountBillingController,
    model,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    Object.assign(controller, model);
  }

  resetController(): void {
    this.store.unloadAll('changeover-setting');
    this.securityDepositSettingRepository.unloadAll();
    this.guestIdentityVerificationConfigurationRepository.unloadAll();

    if (this.currentUser.hasAccessTo('connect.smart_locks')) {
      this.seamConnectionRepositoryService.unloadAll();
    }
  }

  private getUserCustomerOrDefault(user: UserModel): CustomerModel {
    if (user.customer) {
      return user.customer;
    }

    return this.store.createRecord('customer', {
      user,
      currency: 'USD',
      annual: true,
    });
  }
}
