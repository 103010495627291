import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<BsButton @onClick={{this.handleClick}} disabled={{this.disabled}} @type={{this.type}} ...attributes>\n  {{@text}}\n\n  {{#if this.withIcon}}\n    &nbsp;\n\n    <FaIcon @icon=\"clone\" />\n  {{/if}}\n</BsButton>\n", {"contents":"<BsButton @onClick={{this.handleClick}} disabled={{this.disabled}} @type={{this.type}} ...attributes>\n  {{@text}}\n\n  {{#if this.withIcon}}\n    &nbsp;\n\n    <FaIcon @icon=\"clone\" />\n  {{/if}}\n</BsButton>\n","moduleName":"uplisting-frontend/components/ui/copy-button/index.hbs","parseOptions":{"srcName":"uplisting-frontend/components/ui/copy-button/index.hbs"}});
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { type Registry as Services, inject as service } from '@ember/service';
import { action } from '@ember/object';

interface IArgs {
  text: string;
  textToCopy: string;
  messageOnSuccess: string;
  type?: 'light';
  disabled?: boolean;
  withIcon?: false;
}

interface CopyButtonSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

export default class UiCopyButtonComponent extends Component<CopyButtonSignature> {
  @service notifications!: Services['notifications'];

  @cached
  get disabled(): boolean {
    return this.args.disabled ?? false;
  }

  @cached
  get type(): string {
    return this.args.type ?? 'secondary';
  }

  @cached
  get withIcon(): boolean {
    return this.args.withIcon ?? true;
  }

  @action
  async handleClick(): Promise<void> {
    await navigator.clipboard.writeText(this.args.textToCopy);

    this.notifications.info(this.args.messageOnSuccess);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::CopyButton': typeof UiCopyButtonComponent;
  }
}
