import { jwtDecode } from 'jwt-decode';
import { getOwner } from '@ember/application';
import type Transition from '@ember/routing/transition';
import BaseRoute from 'uplisting-frontend/pods/base/route';
import IndexRoute from 'uplisting-frontend/pods/index/route';
import { parseResponse } from 'ember-simple-auth/authenticators/oauth2-implicit-grant';
import { type ISessionTokenDecoded } from 'ember-simple-auth/services/session';
import { getGaClientId } from 'uplisting-frontend/utils';

export default class AuthCallbackRoute extends BaseRoute {
  async beforeModel(transition: Transition) {
    await super.beforeModel(transition);

    this.session.invalidate();

    const hash = parseResponse(window.location.hash);
    const invitationId = hash['state'];

    hash.idToken = hash.access_token;

    await this.session.authenticate('authenticator:auth-url-hash', hash);

    if (!this.session.isAuthenticated) {
      return;
    }

    const decode = jwtDecode<ISessionTokenDecoded>(hash.access_token ?? '');

    if (!decode) {
      return;
    }

    const user = this.store.createRecord('user', {
      email: decode.user.email,
      name: decode.user.fullName || decode.user.email,
      identities: [decode.sub],
      invitation: invitationId,
      gaClientId: getGaClientId(),
    });

    await user.save();

    await this.session.fetchCurrentUser();

    const indexRoute = getOwner(this)?.lookup('route:index') as IndexRoute;

    await indexRoute.redirectAfterLogin();
  }
}
