import Service, {
  type Registry as Services,
  inject as service,
} from '@ember/service';
import { capitalize } from '@ember/string';
import Model from '@ember-data/model';
import DS from 'ember-data';
import { Changeset } from 'ember-changeset';
import { BufferedChangeset } from 'validated-changeset';
import lookupValidator from 'ember-changeset-validations';
import { type Validation } from 'uplisting-frontend/validations';
import type ModelRegistry from 'ember-data/types/registries/model';
import { type RepositoryCache } from 'uplisting-frontend/utils/interfaces';

const baseErrorMsg = 'Services/Repository#BaseRepository';

// NOTE: Those are types to make TS, changeset & ember work together
// so here we are scaling changeset types, to also include the types and properties
// of the model that will be create
type ModelAttributes<T extends Model> = Pick<T, Exclude<keyof T, keyof Model>>;

type ModelProperties<T extends Model> = Readonly<
  Pick<T, Exclude<keyof T, keyof ModelAttributes<T>>>
>;

type ChangesetAttributes<T> = T extends Model
  ? ModelAttributes<T> & ModelProperties<T>
  : T;

export type GenericChangeset<T> = BufferedChangeset &
  ChangesetAttributes<T> & { data: T };

/**
 * Other services which are directly working with models
 * Should inherit from this one
 */
export default class BaseRepositoryService<
  T extends Model,
  K = void,
> extends Service {
  @service store!: Services['store'];
  @service sentry!: Services['sentry'];
  @service notifications!: Services['notifications'];

  recordName!: keyof ModelRegistry & string;
  implementMethods: string[] = [];
  validation!: Validation;

  protected cache: RepositoryCache<K> = {};

  public async findAll(options: object = {}): Promise<DS.PromiseArray<T>> {
    this.assureMethodImplemented('findAll');

    return this.store.findAll(this.recordName, options);
  }

  public peekAll(): T[] {
    this.assureMethodImplemented('peekAll');

    return this.store.peekAll(this.recordName).slice();
  }

  public peekRecord(id: string): T {
    this.assureMethodImplemented('peekRecord');

    return this.store.peekRecord(this.recordName, id);
  }

  public async findRecord(
    id: string,
    options?: object,
  ): Promise<DS.PromiseObject<T>> {
    this.assureMethodImplemented('findRecord');

    return this.store.findRecord(this.recordName, id, options);
  }

  public async save(item: Model): Promise<void> {
    this.assureMethodImplemented('save');

    try {
      await item.save();
    } catch (err) {
      this.notifications.error();

      this.sentry.captureException(err);
    }
  }

  public async query(
    query: object,
    options?: object,
  ): Promise<DS.PromiseArray<T>> {
    this.assureMethodImplemented('query');

    return this.store.query(this.recordName, query, options);
  }

  public unloadAll(): void {
    this.assureMethodImplemented('unloadAll');

    if (this.cache) {
      this.cache = {};
    }

    this.store.unloadAll(this.recordName);
  }

  public unloadRecords(records: Model[]): void {
    this.assureMethodImplemented('unloadRecords');

    records.forEach((record) => {
      record.unloadRecord();
    });
  }

  public async destroyRecord(item: Model): Promise<void> {
    this.assureMethodImplemented('destroyRecord');

    await item.destroyRecord();
  }

  public async updateRecord(item: Model, options?: object): Promise<void> {
    this.assureMethodImplemented('updateRecord');

    await item.save(options);
  }

  public rollback(item: Model): void {
    this.assureMethodImplemented('rollback');

    item.rollbackAttributes();
  }

  public createRecord(options?: object): T {
    this.assureMethodImplemented('createRecord');

    return this.store.createRecord(this.recordName, options);
  }

  public buildChangeset(model?: T | object): GenericChangeset<T> {
    const record = model || this.createRecord();

    return Changeset(
      record,
      lookupValidator(this.validation),
      this.validation,
    ) as GenericChangeset<T>;
  }

  protected getDataFromCache(id: string): K | undefined {
    if (this.cache[id]) {
      return this.cache[id];
    }
  }

  /**
   * @description Raise error if operation is not implemented
   * As all methods for models are inherited from this base repositories service
   * And some of them may not have/need methods described in this class
   * According to the Liskov substitution principle they should throw error
   *
   * @param {String} method - name of the operation to test
   */
  private assureMethodImplemented(method: string): void {
    if (!this.implementMethods.includes(method)) {
      const message = `${capitalize(
        this.recordName,
      )}: method '${method}' is not implemented`;

      this.debug(message);
    }
  }

  private debug(message: string): void {
    this.sentry.captureMessage(`${baseErrorMsg} - ${message}`);
  }
}

declare module '@ember/service' {
  interface Registry {
    'repositories/base': BaseRepositoryService<Model>;
  }
}
