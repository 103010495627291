import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div id=\"rhs\" class=\"col-lg-5\">\n  <div class=\"action-navigation-items\">\n    <div id=\"booking-navigation\">\n      <LinkTo @route={{this.summaryRoute}} @model={{this.changeover.id}} class=\"btn btn-xs {{if (is-route-active this.summaryRoute) \"btn-primary\" \"btn-secondary\"}}\">\n        {{t \"action_changeovers.nav.summary\"}}\n      </LinkTo>\n\n      {{#if (has-access-to \"changeovers.viewHostInfo\")}}\n        <LinkTo @route={{this.hostRoute}} @model={{this.changeover.id}} class=\"btn btn-xs {{if (is-route-active this.hostRoute) \"btn-primary\" \"btn-secondary\"}}\">\n          {{t \"action_changeovers.nav.notes\"}}\n        </LinkTo>\n      {{/if}}\n    </div>\n\n    <LinkTo @route={{this.indexRoute}}>\n      <FaIcon @icon=\"xmark\" @size=\"lg\" class=\"cursor-pointer\" />\n    </LinkTo>\n  </div>\n\n  <br>\n\n  {{yield}}\n</div>\n", {"contents":"<div id=\"rhs\" class=\"col-lg-5\">\n  <div class=\"action-navigation-items\">\n    <div id=\"booking-navigation\">\n      <LinkTo @route={{this.summaryRoute}} @model={{this.changeover.id}} class=\"btn btn-xs {{if (is-route-active this.summaryRoute) \"btn-primary\" \"btn-secondary\"}}\">\n        {{t \"action_changeovers.nav.summary\"}}\n      </LinkTo>\n\n      {{#if (has-access-to \"changeovers.viewHostInfo\")}}\n        <LinkTo @route={{this.hostRoute}} @model={{this.changeover.id}} class=\"btn btn-xs {{if (is-route-active this.hostRoute) \"btn-primary\" \"btn-secondary\"}}\">\n          {{t \"action_changeovers.nav.notes\"}}\n        </LinkTo>\n      {{/if}}\n    </div>\n\n    <LinkTo @route={{this.indexRoute}}>\n      <FaIcon @icon=\"xmark\" @size=\"lg\" class=\"cursor-pointer\" />\n    </LinkTo>\n  </div>\n\n  <br>\n\n  {{yield}}\n</div>\n","moduleName":"uplisting-frontend/components/ui/changeover/index.hbs","parseOptions":{"srcName":"uplisting-frontend/components/ui/changeover/index.hbs"}});
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { type Registry as Services, inject as service } from '@ember/service';
import ChangeoverModel from 'uplisting-frontend/models/changeover';

interface IArgs {
  changeover: ChangeoverModel;
}

export interface ChangeoverSignature {
  Element: HTMLDivElement;

  Args: IArgs;

  Blocks: {
    default: [];
  };
}

export default class UiChangeoverComponent extends Component<ChangeoverSignature> {
  @service router!: Services['router'];

  @cached
  get changeover(): ChangeoverModel {
    return this.args.changeover;
  }

  @cached
  get currentRouteName(): string {
    return this.router.currentRouteName ?? '';
  }

  @cached
  get summaryRoute(): string {
    if (this.currentRouteName.startsWith('action.changeovers')) {
      return 'action.changeovers.summary';
    }

    return '';
  }

  @cached
  get hostRoute(): string {
    if (this.currentRouteName.startsWith('action.changeovers')) {
      return 'action.changeovers.host';
    }

    return '';
  }

  @cached
  get indexRoute(): string {
    if (this.currentRouteName.startsWith('action')) {
      return 'action';
    }

    return '';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Changeover': typeof UiChangeoverComponent;
  }
}
