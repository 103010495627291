import { hash } from 'rsvp';
import { type Registry as Services, inject as service } from '@ember/service';
import ProtectedRoute from 'uplisting-frontend/pods/base/protected-route';

export default class DirectPromotionsRoute extends ProtectedRoute {
  @service('repositories/property')
  propertyRepository!: Services['repositories/property'];

  @service('repositories/promotion')
  promotionRepository!: Services['repositories/promotion'];

  @service('repositories/promotion-redemption')
  promotionRedemptionRepository!: Services['repositories/promotion-redemption'];

  model() {
    return hash({
      properties: this.propertyRepository.findAll(),
      promotions: this.promotionRepository.findAll(),
    });
  }

  resetController(): void {
    this.propertyRepository.unloadAll();
    this.promotionRepository.unloadAll();
    this.promotionRedemptionRepository.unloadAll();
  }
}
