import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div local-class=\"identity-image-wrapper\" data-test-guest-identity-image>\n  <p class=\"mb-2\">{{t \"action_bookings_identity.image.title\"}}</p>\n\n  <BsButton\n    class=\"btn-xs\"\n    @type=\"secondary\"\n    @onClick={{this.handleDownloadImage}}\n    @disabled={{this.isDownloading}}\n    data-test-guest-identity-image-download\n  >\n    {{t \"action_bookings_identity.image.download\" side=this.image.side}}\n\n    &nbsp;\n\n    {{#if this.isDownloading}}\n      <FaIcon @icon=\"refresh\" @spin={{true}} />\n    {{/if}}\n  </BsButton>\n\n  <hr />\n\n  <img src={{this.image.url}} local-class=\"identity-image\" alt={{t \"images.alt\"}} />\n</div>\n", {"contents":"<div local-class=\"identity-image-wrapper\" data-test-guest-identity-image>\n  <p class=\"mb-2\">{{t \"action_bookings_identity.image.title\"}}</p>\n\n  <BsButton\n    class=\"btn-xs\"\n    @type=\"secondary\"\n    @onClick={{this.handleDownloadImage}}\n    @disabled={{this.isDownloading}}\n    data-test-guest-identity-image-download\n  >\n    {{t \"action_bookings_identity.image.download\" side=this.image.side}}\n\n    &nbsp;\n\n    {{#if this.isDownloading}}\n      <FaIcon @icon=\"refresh\" @spin={{true}} />\n    {{/if}}\n  </BsButton>\n\n  <hr />\n\n  <img src={{this.image.url}} local-class=\"identity-image\" alt={{t \"images.alt\"}} />\n</div>\n","moduleName":"uplisting-frontend/components/ui/booking/identity/image/index.hbs","parseOptions":{"srcName":"uplisting-frontend/components/ui/booking/identity/image/index.hbs"}});
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { type Registry as Services, inject as service } from '@ember/service';
import GuestIdentityImageModel from 'uplisting-frontend/models/guest-identity-image';

interface IArgs {
  image: GuestIdentityImageModel;
}

export interface BookingIdentityImageSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

export default class UiBookingIdentityImageComponent extends Component<BookingIdentityImageSignature> {
  @service store!: Services['store'];
  @service notifications!: Services['notifications'];

  @service('repositories/guest-identity-image')
  guestIdentityImageRepository!: Services['repositories/guest-identity-image'];

  @cached @tracked isDownloading = false;

  @cached
  get image(): GuestIdentityImageModel {
    return this.args.image;
  }

  @action
  async handleDownloadImage(): Promise<void> {
    this.isDownloading = true;

    const { image } = this;

    try {
      await this.guestIdentityImageRepository.getRecords(
        image.guestIdentityVerificationId,
      );

      window.open(image.url, '_blank');
    } catch {
      this.notifications.error();
    } finally {
      this.isDownloading = false;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Booking::Identity::Image': typeof UiBookingIdentityImageComponent;
  }
}
