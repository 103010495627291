import { cached } from '@glimmer/tracking';
import { type Registry as Services, inject as service } from '@ember/service';
import { action } from '@ember/object';
import { type ILink } from 'uplisting-frontend/components/ui/nav-links';
import BaseController from 'uplisting-frontend/pods/base/controller';
import ClientsRoute from 'uplisting-frontend/pods/clients/route';

export default class ClientsController extends BaseController {
  @service pricing!: Services['pricing'];
  @service features!: Services['features'];

  route!: ClientsRoute;

  @cached
  get navLinks(): ILink[] {
    const { currentUser } = this;

    return [
      {
        name: this.intl.t('nav_links.clients.index'),
        route: 'clients.index',
        permitted: currentUser.hasAccessTo('clients.index'),
      },
      {
        name: this.intl.t('nav_links.clients.statements'),
        route: 'clients.statements',
        permitted: currentUser.hasAccessTo('client_statements.index'),
      },
      {
        name: this.intl.t('nav_links.clients.account_details'),
        route: 'clients.account-details',
        permitted:
          currentUser.hasAccessTo('client_statements.settings') &&
          this.features.isClientStatementsEnabledOnAccount,
      },
      {
        name: this.intl.t('nav_links.clients.management_fee_rules'),
        route: 'clients.management-fee-rules',
        permitted:
          currentUser.hasAccessTo('management_fee_rules.index') &&
          this.features.isClientStatementsEnabledOnAccount,
      },
    ];
  }

  @cached
  get isClientStatementsAndManagementDisabled(): boolean {
    return (
      !this.features.isClientManagementEnabledOnAccount &&
      !this.features.isClientStatementsEnabledOnAccount
    );
  }

  @cached
  get isDeactivateRoute(): boolean {
    const routeName = this.router.currentRoute?.name;

    return (
      routeName === 'clients.deactivate' ||
      routeName === 'clients.statements.deactivate'
    );
  }

  @cached
  get showNavbar(): boolean {
    if (this.isClientStatementsAndManagementDisabled) {
      return false;
    }

    return !this.isDeactivateRoute;
  }

  @action
  async handleClientManagementModalSubmit(): Promise<void> {
    await this.accountConfigurationRepository.enableClientManagement();

    await this.route.refresh();
  }

  @action
  async handleClientStatementsModalSubmit(): Promise<void> {
    await this.accountConfigurationRepository.enableClientStatements();

    try {
      await this.router.transitionTo('clients.statements.index');
    } catch (err) {
      // ignore in case of error, here `TransitionAborted` is being thrown for some reason
      if ((err as Error).message !== 'TransitionAborted') {
        throw err;
      }
    }
  }
}
