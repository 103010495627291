import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { cached } from '@glimmer/tracking';
import { type Registry as Services, inject as service } from '@ember/service';
import { differenceInDays } from 'date-fns/differenceInDays';
import { isBefore } from 'date-fns/isBefore';
import { addHours } from 'date-fns/addHours';
import { isSameDay } from 'date-fns/isSameDay';
import {
  type IBookingSchema,
  BookingStatus,
  BookingSource,
  BookingChannel,
} from 'uplisting-frontend/models/schemas';
import CalendarModel from 'uplisting-frontend/models/calendar';
import OccasionModel from 'uplisting-frontend/models/occasion';
import LockCodeModel from 'uplisting-frontend/models/lock-code';
import PropertyModel from 'uplisting-frontend/models/property';
import PriceModel from 'uplisting-frontend/models/price';
import MultiUnitModel from 'uplisting-frontend/models/multi-unit';
import MessageModel from 'uplisting-frontend/models/message';
import ChannelUserModel from 'uplisting-frontend/models/channel-user';
import GuestInvoiceModel from 'uplisting-frontend/models/guest-invoice';
import BookingPaymentModel from 'uplisting-frontend/models/booking-payment';
import HomeawayActionModel from 'uplisting-frontend/models/homeaway-action';
import SecurityDepositModel from 'uplisting-frontend/models/security-deposit';
import ScheduledMessageModel from 'uplisting-frontend/models/scheduled-message';
import BalanceAdjustmentModel from 'uplisting-frontend/models/balance-adjustment';
import HistoryBookingPriceModel from 'uplisting-frontend/models/history-booking-price';
import BookingDotComActionModel from 'uplisting-frontend/models/booking-dot-com-action';
import BookingPaymentActionModel from 'uplisting-frontend/models/booking-payment-action';
import SecurityDepositActionModel from 'uplisting-frontend/models/security-deposit-action';
import BookingRentalAgreementModel from 'uplisting-frontend/models/booking-rental-agreement';
import AdditionalBookingChargeModel from 'uplisting-frontend/models/additional-booking-charge';
import BookingManagementFeeRuleModel from 'uplisting-frontend/models/booking-management-fee-rule';
import GuestIdentityVerificationModel from 'uplisting-frontend/models/guest-identity-verification';
import BookingManagementFeeSettingModel from 'uplisting-frontend/models/booking-management-fee-setting';
import BookingRentalAgreementActionModel from 'uplisting-frontend/models/booking-rental-agreement-action';
import GuestIdentityVerificationActionModel from 'uplisting-frontend/models/guest-identity-verification-action';

const MOVEABLE_CHANNELS = [
  BookingChannel.airbnbOfficial,
  BookingChannel.bookingDotCom,
  BookingChannel.google,
  BookingChannel.homeAway,
  BookingChannel.uplisting,
];

const CHANNELS_WITH_PAYMENTS = [
  BookingChannel.bookingDotCom,
  BookingChannel.google,
  BookingChannel.homeAway,
  BookingChannel.uplisting,
];

const PRICE_EDITABLE_CHANNELS = [
  BookingChannel.airbnbOfficial,
  BookingChannel.bookingDotCom,
  BookingChannel.google,
  BookingChannel.uplisting,
  BookingChannel.homeAway,
  BookingChannel.partnerIcal,
];

export default class BookingModel extends Model implements IBookingSchema {
  @service translations!: Services['translations'];

  @attr('string') arrivalTime!: string;
  @attr('string') channel!: BookingChannel;
  @attr('string') checkIn!: string;
  @attr('string') checkOut!: string;
  @attr('string') departureTime!: string;
  @attr('string') guestName!: string;
  @attr('string') guestPhone!: string;
  @attr('string') status!: BookingStatus;
  @attr('string') guestEmail!: string;
  @attr('string') channelName!: string;
  @attr('string') preferredGuestName!: string;
  @attr('boolean') imported!: boolean;
  @attr('boolean') deletable!: boolean;
  @attr('date') createdAt!: Date;
  @attr('date') bookedAt!: Date;
  @attr('string') note!: string;
  @attr('string') externalReservationId!: string;
  @attr('string') externalReservationLink!: string;
  @attr('boolean') viaApi!: boolean;
  @attr('boolean') automatedMessagesEnabled!: boolean;
  @attr('boolean') automatedReviewsEnabled!: boolean;
  @attr('boolean') automatedReviewSent!: boolean;
  @attr('boolean') problematic!: boolean;
  @attr('number') expiresIn!: number;
  @attr('string') bookingConfirmationPageUrl!: string;
  @attr('boolean') lockCodeEditable!: boolean;
  @attr('string') customLockCode!: string | null;
  @attr('boolean') direct!: boolean;
  @attr('string') source!: BookingSource;

  @cached
  get displayChannel(): string {
    if (this.channel) {
      return this.translations.getChannelNameFor(this.channel);
    }

    return this.channelName;
  }

  @cached
  get displayGuestName(): string {
    return this.preferredGuestName ?? this.guestName;
  }

  @cached
  get displayName(): string {
    const {
      multiUnit,
      property: { nickname },
    } = this;

    if (multiUnit) {
      return `${multiUnit.name} - ${nickname}`;
    }

    return nickname;
  }

  @cached
  get isGoogle(): boolean {
    return this.channel === BookingChannel.google;
  }

  @cached
  get isUplisting(): boolean {
    return this.channel === BookingChannel.uplisting;
  }

  @cached
  get isAirbnbOfficial(): boolean {
    return this.channel === BookingChannel.airbnbOfficial;
  }

  @cached
  get isVrbo(): boolean {
    return this.channel === BookingChannel.homeAway && this.viaApi;
  }

  @cached
  get isBookingDotCom(): boolean {
    return this.channel === BookingChannel.bookingDotCom;
  }

  @cached
  get isIcal(): boolean {
    if (this.viaApi) {
      return false;
    }

    return !(this.isUplisting || this.isGoogle);
  }

  @cached
  get isPending(): boolean {
    return this.status === BookingStatus.pending;
  }

  @cached
  get isCancelled(): boolean {
    return this.status === BookingStatus.cancelled;
  }

  @cached
  get duration(): number {
    return differenceInDays(new Date(this.checkOut), new Date(this.checkIn));
  }

  @cached
  get currency(): string {
    return this.price.currency ?? this.property.currency;
  }

  @cached
  get canSendMessage(): boolean {
    switch (this.channel) {
      case BookingChannel.bookingDotCom:
      case BookingChannel.airbnbOfficial:
        return true;
      case BookingChannel.homeAway:
        return this.viaApi;
      case BookingChannel.google:
      case BookingChannel.uplisting:
        return !!this.guestEmail;
      default:
        return false;
    }
  }

  @cached
  get messagesSorted(): MessageModel[] {
    return this.messages.slice().sort((x, y) => {
      if (isBefore(x.createdAt, y.createdAt)) {
        return -1;
      } else if (isBefore(y.createdAt, x.createdAt)) {
        return 1;
      }

      return 0;
    });
  }

  @cached
  get checkInDate(): Date {
    return new Date(`${this.checkIn}:${this.arrivalTime}`);
  }

  @cached
  get checkOutDate(): Date {
    return new Date(`${this.checkOut}:${this.departureTime}`);
  }

  @cached
  get expiresInTime(): Date {
    return addHours(this.createdAt, this.expiresIn);
  }

  @cached
  get isCheckedIn(): boolean {
    return this.status === BookingStatus.checkedIn;
  }

  @cached
  get isCheckedOut(): boolean {
    return this.status === BookingStatus.checkedOut;
  }

  @cached
  get needsCheckIn(): boolean {
    return this.status === BookingStatus.needsCheckIn;
  }

  @cached
  get needsCheckOut(): boolean {
    return this.status === BookingStatus.needsCheckOut;
  }

  @cached
  get isCheckoutToday(): boolean {
    return isSameDay(this.checkOut, new Date());
  }

  @cached
  get isManual(): boolean {
    return this.isUplisting && !this.direct && !this.viaApi;
  }

  @cached
  get isMoveable(): boolean {
    return MOVEABLE_CHANNELS.includes(this.channel);
  }

  @cached
  get isPayable(): boolean {
    return CHANNELS_WITH_PAYMENTS.includes(this.channel);
  }

  @cached
  get isPriceEditable(): boolean {
    return PRICE_EDITABLE_CHANNELS.includes(this.channel) && this.price.visible;
  }

  @cached
  get isEditable(): boolean {
    return this.isUplisting || this.isGoogle || this.isVrbo;
  }

  @belongsTo('occasion', { inverse: null, async: false })
  occasion!: OccasionModel;

  @belongsTo('property', { inverse: null, async: false })
  property!: PropertyModel;

  @belongsTo('property', { inverse: null, async: false })
  newProperty?: PropertyModel;

  @belongsTo('calendar', { inverse: 'bookings', async: false })
  calendar!: CalendarModel;

  @belongsTo('price', { inverse: 'booking', async: false })
  price!: PriceModel;

  @belongsTo('multi-unit', { inverse: null, async: false })
  multiUnit?: MultiUnitModel;

  @belongsTo('guest-identity-verification', {
    inverse: 'booking',
    async: false,
  })
  guestIdentityVerification?: GuestIdentityVerificationModel;

  @belongsTo('channel-user', { async: false, inverse: null })
  channelUser!: ChannelUserModel;

  @belongsTo('booking-payment', { async: false, inverse: 'booking' })
  bookingPayment?: BookingPaymentModel;

  @belongsTo('lock-code', { async: false, inverse: 'booking' })
  lockCode?: LockCodeModel;

  @belongsTo('security-deposit', { async: false, inverse: 'booking' })
  securityDeposit!: SecurityDepositModel;

  @belongsTo('homeaway-action', { async: false, inverse: 'booking' })
  homeawayAction!: HomeawayActionModel;

  @belongsTo('booking-dot-com-action', { async: false, inverse: 'booking' })
  bookingDotComAction!: BookingDotComActionModel;

  @belongsTo('booking-payment-action', { async: false, inverse: 'booking' })
  bookingPaymentAction!: BookingPaymentActionModel;

  @belongsTo('security-deposit-action', { async: false, inverse: 'booking' })
  securityDepositAction!: SecurityDepositActionModel;

  @belongsTo('booking-rental-agreement', { async: false, inverse: 'booking' })
  bookingRentalAgreement!: BookingRentalAgreementModel;

  @belongsTo('booking-rental-agreement-action', {
    async: false,
    inverse: 'booking',
  })
  bookingRentalAgreementAction!: BookingRentalAgreementActionModel;

  @belongsTo('guest-identity-verification-action', {
    async: false,
    inverse: 'booking',
  })
  guestIdentityVerificationAction!: GuestIdentityVerificationActionModel;

  @belongsTo('booking-management-fee-rule', {
    async: false,
    inverse: 'booking',
  })
  managementFeeRule!: BookingManagementFeeRuleModel;

  @belongsTo('booking-management-fee-setting', {
    async: false,
    inverse: 'booking',
  })
  bookingManagementFeeSetting?: BookingManagementFeeSettingModel;

  @hasMany('message', { async: false, inverse: 'booking' })
  messages!: MessageModel[];

  @hasMany('scheduled-message', { inverse: 'booking', async: false })
  scheduledMessages!: ScheduledMessageModel[];

  @hasMany('guest-invoice', { inverse: 'booking', async: false })
  guestInvoices!: GuestInvoiceModel[];

  @hasMany('balance-adjustment', { inverse: 'booking', async: false })
  balanceAdjustments!: BalanceAdjustmentModel[];

  @hasMany('history-booking-price', { inverse: null, async: false })
  priceChanges!: HistoryBookingPriceModel[];

  @hasMany('additional-booking-charge', { inverse: null, async: false })
  pendingAdditionalCharges!: AdditionalBookingChargeModel[];
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    booking: BookingModel;
  }
}
