import { type Registry as Services, inject as service } from '@ember/service';
import DS from 'ember-data';
import type Transition from '@ember/routing/transition';
import ProtectedRoute from 'uplisting-frontend/pods/base/protected-route';
import ClientModel from 'uplisting-frontend/models/client';
import ClientsClientController from 'uplisting-frontend/pods/clients/client/controller';

export default class ClientsClientRoute extends ProtectedRoute {
  @service('repositories/client')
  clientRepository!: Services['repositories/client'];

  model({ id }: { id: string }): Promise<DS.PromiseObject<ClientModel>> {
    return this.clientRepository.findRecord(id, {
      reload: true,
      include:
        'client_properties,invitations,permissions,tax,users,permissions.parent',
    });
  }

  setupController(
    controller: ClientsClientController,
    model,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    Object.assign(controller, { client: model });
  }
}
