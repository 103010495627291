import { type Registry as Services, inject as service } from '@ember/service';
import { hash } from 'rsvp';
import type Transition from '@ember/routing/transition';
import ProtectedRoute from 'uplisting-frontend/pods/base/protected-route';
import ClientsClientProfileController from 'uplisting-frontend/pods/clients/client/profile/controller';

export default class ClientsClientProfileRoute extends ProtectedRoute {
  @service('repositories/tax') taxRepository!: Services['repositories/tax'];

  model() {
    return hash({
      client: this.modelFor('clients.client'),
      taxes: this.taxRepository.findAll(),
    });
  }

  setupController(
    controller: ClientsClientProfileController,
    model,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    if (!model.client.tax.get('id')) {
      model.client.tax = this.taxRepository.defaultTaxOption;
    }

    Object.assign(controller, model);
  }
}
