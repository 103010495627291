import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<BsForm\n  class=\"action-bookings-wrapper\"\n  @model={{@changeover}}\n  @onSubmit={{this.handleSaveChangeover}}\n  data-test-changeover-notes-form\nas |form|>\n  <form.element @property=\"hostNote\" @label={{t \"action_changeovers_host.form.host_note.label\"}} @controlType=\"textarea\" as |el|>\n    <el.control rows=\"6\" data-test-changeover-host-note />\n  </form.element>\n\n  <form.submitButton class=\"width-full btn-sm mb-2\" data-test-changeover-notes-save-button>\n    {{t \"action_changeovers_host.form.submit\"}}\n  </form.submitButton>\n</BsForm>\n", {"contents":"<BsForm\n  class=\"action-bookings-wrapper\"\n  @model={{@changeover}}\n  @onSubmit={{this.handleSaveChangeover}}\n  data-test-changeover-notes-form\nas |form|>\n  <form.element @property=\"hostNote\" @label={{t \"action_changeovers_host.form.host_note.label\"}} @controlType=\"textarea\" as |el|>\n    <el.control rows=\"6\" data-test-changeover-host-note />\n  </form.element>\n\n  <form.submitButton class=\"width-full btn-sm mb-2\" data-test-changeover-notes-save-button>\n    {{t \"action_changeovers_host.form.submit\"}}\n  </form.submitButton>\n</BsForm>\n","moduleName":"uplisting-frontend/components/ui/changeover/host/index.hbs","parseOptions":{"srcName":"uplisting-frontend/components/ui/changeover/host/index.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { type Registry as Services, inject as service } from '@ember/service';
import ChangeoverModel from 'uplisting-frontend/models/changeover';

interface IArgs {
  changeover: ChangeoverModel;
}

export interface ChangeoverHostSignature {
  Element: HTMLFormElement;

  Args: IArgs;
}

export default class UiChangeoverHostComponent extends Component<ChangeoverHostSignature> {
  @service notifications!: Services['notifications'];

  @action
  async handleSaveChangeover(): Promise<void> {
    try {
      await this.args.changeover.save();

      this.notifications.info('action_changeovers_host.notifications.save');
    } catch {
      this.notifications.error();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Changeover::Host': typeof UiChangeoverHostComponent;
  }
}
