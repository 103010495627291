import config from 'ember-get-config';
import { cached } from '@glimmer/tracking';
import type Transition from '@ember/routing/transition';
import BaseRoute from 'uplisting-frontend/pods/base/route';
import UserModel from 'uplisting-frontend/models/user';

export default class ProtectedRoute extends BaseRoute {
  @cached
  get currentUser(): UserModel {
    return this.session.currentUser as UserModel;
  }

  async beforeModel(transition: Transition): Promise<void> {
    await super.beforeModel(transition);

    this.session.requireAuthentication(transition, () => {
      transition.abort();

      window.open(config.auth.loginUrl, '_self');
    });
  }
}
