import { type Registry as Services, inject as service } from '@ember/service';
import type Transition from '@ember/routing/transition';
import BaseRoute from 'uplisting-frontend/pods/base/route';
import { type IParams } from 'uplisting-frontend/pods/-sidebar/bookings/route';
import ActionBookingsLogsController from 'uplisting-frontend/pods/action/bookings/logs/controller';

export default abstract class SidebarBookingsLogsRoute extends BaseRoute {
  @service('repositories/booking')
  bookingRepository!: Services['repositories/booking'];

  abstract parentRoute: string;

  model() {
    const params = this.paramsFor(this.parentRoute) as IParams;

    return this.bookingRepository.findRecord(params.booking_id, {
      reload: true,
      include:
        'price,scheduled_messages,scheduled_messages.automated_message_rule',
    });
  }

  setupController(
    controller: ActionBookingsLogsController,
    model,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    Object.assign(controller, { booking: model });
  }
}
