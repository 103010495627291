import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#each this.parentsByCategory as |item|}}\n  <div local-class=\"client-section {{if (eq @type \"small\") \"__small\"}}\" data-test-parent-permission>\n    {{#if item.category}}\n      <p local-class=\"section-title\" data-test-parent-permission-category>{{item.category}}</p>\n    {{/if}}\n\n    {{#each-in item.subCategories as |subCategory permissions|}}\n      {{#if subCategory}}\n        <p local-class=\"section-subtitle\" data-test-permission-sub-category>\n          {{subCategory}}\n        </p>\n      {{/if}}\n\n      {{#each permissions as |permission|}}\n        <Ui::Permissions::Row\n          @level={{if subCategory 1 0}}\n          @item={{permission}}\n          @onUpdate={{this.handleUpdatePermission}}\n        />\n      {{/each}}\n    {{/each-in}}\n  </div>\n{{/each}}\n", {"contents":"{{#each this.parentsByCategory as |item|}}\n  <div local-class=\"client-section {{if (eq @type \"small\") \"__small\"}}\" data-test-parent-permission>\n    {{#if item.category}}\n      <p local-class=\"section-title\" data-test-parent-permission-category>{{item.category}}</p>\n    {{/if}}\n\n    {{#each-in item.subCategories as |subCategory permissions|}}\n      {{#if subCategory}}\n        <p local-class=\"section-subtitle\" data-test-permission-sub-category>\n          {{subCategory}}\n        </p>\n      {{/if}}\n\n      {{#each permissions as |permission|}}\n        <Ui::Permissions::Row\n          @level={{if subCategory 1 0}}\n          @item={{permission}}\n          @onUpdate={{this.handleUpdatePermission}}\n        />\n      {{/each}}\n    {{/each-in}}\n  </div>\n{{/each}}\n","moduleName":"uplisting-frontend/components/ui/permissions/index.hbs","parseOptions":{"srcName":"uplisting-frontend/components/ui/permissions/index.hbs"}});
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import sortBy from 'lodash-es/sortBy';
import { action } from '@ember/object';
import groupBy from 'lodash-es/groupBy';
import { type Registry as Services, inject as service } from '@ember/service';
import ClientModel from 'uplisting-frontend/models/client';
import TeammateModel from 'uplisting-frontend/models/teammate';
import PermissionModel from 'uplisting-frontend/models/permission';

type PermissionsParentRecord = ClientModel | TeammateModel;

interface IArgs {
  record: PermissionsParentRecord;
  type?: 'small';
  successNotificationKey?: string;
}

interface PermissionsSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

export type PermissionsChildrenGrouped = Record<string, PermissionModel[]>;

interface IPermissionsGrouped {
  category: string;
  subCategories: PermissionsChildrenGrouped;
}

function sortPermissions(
  parents: PermissionModel[],
): PermissionsChildrenGrouped {
  const grouped = groupBy(parents, 'subCategory');
  const sorted = Object.entries(grouped).sort();

  return Object.fromEntries(sorted) as PermissionsChildrenGrouped;
}

export default class UiPermissionsComponent extends Component<PermissionsSignature> {
  @service notifications!: Services['notifications'];

  @cached
  get successNotificationKey(): string {
    return this.args.successNotificationKey ?? 'notifications.applied';
  }

  @cached
  get parentPermissions(): PermissionModel[] {
    return sortBy(
      this.args.record.permissions.filter((item) => !item.parent),
      'category',
    );
  }

  @cached
  get parentsByCategory(): IPermissionsGrouped[] {
    const grouped = groupBy(this.parentPermissions, 'category');

    return Object.entries(grouped).map(([category, parents]) => ({
      category,
      subCategories: sortPermissions(parents as PermissionModel[]),
    }));
  }

  @action
  async handleUpdatePermission(item: PermissionModel): Promise<void> {
    const isPermitted = item.permitted;

    try {
      item.permitted = !item.permitted;

      await item.save();

      this.notifications.info(this.successNotificationKey);
    } catch {
      item.permitted = isPermitted;

      this.notifications.error();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Permissions': typeof UiPermissionsComponent;
  }
}
