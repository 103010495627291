import Controller from '@ember/controller';
import { type Registry as Services, inject as service } from '@ember/service';
import { cached } from '@glimmer/tracking';
import { action } from '@ember/object';
import AccountConfigurationModel from 'uplisting-frontend/models/account-configuration';
import UserModel from 'uplisting-frontend/models/user';

export default class BaseController extends Controller {
  @service intl!: Services['intl'];
  @service store!: Services['store'];
  @service router!: Services['router'];
  @service session!: Services['session'];
  @service notifications!: Services['notifications'];

  @service('repositories/account-configuration')
  accountConfigurationRepository!: Services['repositories/account-configuration'];

  @cached
  get currentAccountConfiguration(): AccountConfigurationModel {
    return this.accountConfigurationRepository.currentAccountConfiguration;
  }

  @cached
  get defaultTimezone(): string {
    return this.currentAccountConfiguration.timezone;
  }

  @cached
  get currentUser(): UserModel {
    return this.session.currentUser as UserModel;
  }

  @action
  handleContactSupport(): void {
    window.Intercom?.('showNewMessage');
  }
}
