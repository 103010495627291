import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<BsAlert\n  @type=\"info\"\n  @dismissible={{false}}\n  local-class=\"time-zone-selector {{if @collapsible \"__is-collapsible\"}}\"\n  {{this.handleSetTimezone}}\n>\n  {{t \"time_zone.select\"}}\n\n  <PowerSelect\n    @options={{this.timezones}}\n    @selected={{this.timezone}}\n    @onChange={{@onTimeZoneSelect}}\n    @searchEnabled={{true}}\n    local-class=\"time-zone-selector-list\"\n  as |timezone|>\n    {{timezone}}\n  </PowerSelect>\n</BsAlert>\n", {"contents":"<BsAlert\n  @type=\"info\"\n  @dismissible={{false}}\n  local-class=\"time-zone-selector {{if @collapsible \"__is-collapsible\"}}\"\n  {{this.handleSetTimezone}}\n>\n  {{t \"time_zone.select\"}}\n\n  <PowerSelect\n    @options={{this.timezones}}\n    @selected={{this.timezone}}\n    @onChange={{@onTimeZoneSelect}}\n    @searchEnabled={{true}}\n    local-class=\"time-zone-selector-list\"\n  as |timezone|>\n    {{timezone}}\n  </PowerSelect>\n</BsAlert>\n","moduleName":"uplisting-frontend/components/ui/time-zone-selector/index.hbs","parseOptions":{"srcName":"uplisting-frontend/components/ui/time-zone-selector/index.hbs"}});
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { type Registry as Services, inject as service } from '@ember/service';
import { modifier } from 'ember-modifier';
import { timezones } from 'uplisting-frontend/utils';

interface IArgs {
  collapsible?: boolean;
  timezone: string;
  onTimeZoneSelect(timezone: string): void;
}

interface TimeZoneSelectorSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

export default class UiTimeZoneSelectorComponent extends Component<TimeZoneSelectorSignature> {
  @service('repositories/account-configuration')
  accountConfigurationRepository!: Services['repositories/account-configuration'];

  timezones = timezones;

  @cached @tracked timezone!: string;

  handleSetTimezone = modifier(() => {
    this.timezone =
      this.args.timezone ??
      this.accountConfigurationRepository.currentAccountConfiguration.timezone;
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::TimeZoneSelector': typeof UiTimeZoneSelectorComponent;
  }
}
