import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div local-class=\"date-picker\" data-test-date-picker ...attributes>\n  <BsDropdown @closeOnMenuClick={{false}} as |dd|>\n    <dd.toggle data-test-date-picker-toggle>\n      {{date-format this.startDate \"d MMM, yyyy\"}}\n\n      {{#if this.endDate}}\n        {{t \"words.to\"}}\n\n        {{date-format this.endDate \"d MMM, yyyy\"}}\n      {{/if}}\n    </dd.toggle>\n\n    {{!-- template-lint-disable no-at-ember-render-modifiers --}}\n    <dd.menu {{did-insert (fn this.handleSetupPicker dd)}} {{will-destroy this.handleWillDestroy}} data-test-date-picker-menu />\n  </BsDropdown>\n</div>\n", {"contents":"<div local-class=\"date-picker\" data-test-date-picker ...attributes>\n  <BsDropdown @closeOnMenuClick={{false}} as |dd|>\n    <dd.toggle data-test-date-picker-toggle>\n      {{date-format this.startDate \"d MMM, yyyy\"}}\n\n      {{#if this.endDate}}\n        {{t \"words.to\"}}\n\n        {{date-format this.endDate \"d MMM, yyyy\"}}\n      {{/if}}\n    </dd.toggle>\n\n    {{!-- template-lint-disable no-at-ember-render-modifiers --}}\n    <dd.menu {{did-insert (fn this.handleSetupPicker dd)}} {{will-destroy this.handleWillDestroy}} data-test-date-picker-menu />\n  </BsDropdown>\n</div>\n","moduleName":"uplisting-frontend/components/ui/picker/date/index.hbs","parseOptions":{"srcName":"uplisting-frontend/components/ui/picker/date/index.hbs"}});
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { type Registry as Services, inject as service } from '@ember/service';
import Litepicker from 'litepicker';
import { type IDropdown } from 'ember-bootstrap/components/bs-dropdown';
import { utcDate } from 'uplisting-frontend/utils';

interface IArgs {
  startDate: Date | '-' | undefined;
  endDate?: Date;
  singleMode?: false;

  onDateSelect(date1: Date, date2?: Date): void;
}

interface PickerDateRangeSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

export default class UiPickerDateComponent extends Component<PickerDateRangeSignature> {
  @service intl!: Services['intl'];

  @cached @tracked picker!: Litepicker;

  @cached @tracked dropdown?: IDropdown;

  @cached
  get startDate(): Date | '-' | undefined {
    return this.args.startDate;
  }

  @cached
  get endDate(): Date | undefined {
    return this.args.endDate;
  }

  @cached
  get singleMode(): boolean {
    return this.args.singleMode ?? true;
  }

  @action
  handleSetupPicker(dd: IDropdown, element: HTMLDivElement): void {
    this.dropdown = dd;

    this.picker = new Litepicker({
      element,
      startDate: this.startDate,
      endDate: this.endDate,
      parentEl: element,
      singleMode: this.singleMode,
      lang: this.intl.primaryLocale,
      position: 'top left',
    });

    this.picker.on('selected', (value1, value2) => {
      const params: [Date] | [Date, Date] = [utcDate(value1.dateInstance)];

      if (value2) {
        params.push(utcDate(value2.dateInstance));
      }

      this.args.onDateSelect(...params);

      this.handleWillDestroy();
    });

    this.picker.show();
  }

  @action
  handleWillDestroy(): void {
    this.picker.hide();
    this.picker.destroy();
    this.dropdown?.closeDropdown();
    this.dropdown = undefined;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Picker::Date': typeof UiPickerDateComponent;
  }
}
